'use strict';

/**
 * Convenient document.getElementById delegate
 * @param {String} s element id
 * @return {DOMNode} node with id 's' or null if dne
 */
function gid(s) {
    return document.getElementById(s);
}

// RESULTS TABLE VIEW
/**
 * Convenient document.getElementById delegate
 * @param {String} l element id
 * @param {String} s element id
 * @param {String} c element id
 * @return {DOMNode} node with id 's' or null if dne
 */
function structureItem(l, s, c) {
    let country = c;
    let state = s;
    let city = l;
    if (city.length===0) city = '';
    else city += ', ';
    if (state.length===0) state = '';
    if (country.length===0 || country==='United States') country = '';

    return city+state+country;
}

(function main() {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'json';
    xhr.addEventListener('load', function() {
        gData = xhr.response;
        console.log(gData);
        init();
    });
    xhr.open('GET', /localhost/.test(location.href) ? 'http://courageous-rolodex.s3-website-us-east-1.amazonaws.com/app.json' : 'app.json');
    xhr.send();
}());
/**
 * Init function
 */
function init() {
    for (let i in gData) {
        if (gData[i]!==null) {
            gData[i].location = structureItem(gData[i].city, gData[i].state, gData[i].country);
            gData[i].selected = false;
        }
    }

    let menuItem = rolowrapper.querySelectorAll('.menu-item');// might have to move this
    menuItem.forEach(div => {
        div.addEventListener('mouseover', function() {
            let img = div.querySelector('.menu-icon');
            let txt = div.querySelector('.menu-label');
            img.style.backgroundPosition = '-20px';
            txt.style.color = '#b59343';
        });

        div.addEventListener('mouseout', function() {
            let img = div.querySelector('.menu-icon');
            let txt = div.querySelector('.menu-label');
            img.style.backgroundPosition = '0px';
            txt.style.color = '#000000';
        });

        div.addEventListener('click', function() {
            hideMenu();
            if (div.id==='menu1') menuAdd();
            if (div.id==='menu2') menuCopy();
            if (div.id==='menu3') menuShare();
            if (div.id==='hamburgerMenu1') selectAll();
            if (div.id==='hamburgerMenu2') deselectAll();
            if (div.id==='hamburgerCartMenu1') promptPDFDownload();
            if (div.id==='hamburgerCartMenu2') promptExcelDownload();
            if (div.id==='hamburgerCartMenu3') cartCopy();
            if (div.id==='hamburgerCartMenu4') cartShare();
        });
    });

    /*
    pdfNameInput.addEventListener('focus', setCursorPDF);
    pdfNameInput.addEventListener('keyup', nameValidationPDF);
    */
    search.addEventListener('focus', setCursor);
    search.addEventListener('keyup', searchSuggestion);
    resetBtn.addEventListener('click', resetSearch);
    roleMenu.addEventListener('mouseleave', function() {
        let wrapper = document.getElementById('roleWrapper');
        wrapper.className = 'dropdownWrapper closed';
    });

    locMenu.addEventListener('mouseleave', function() {
        let wrapper = document.getElementById('locWrapper');
        wrapper.className = 'dropdownWrapper closed';
    });
    setDropDownArrays();
    setDropDowns();
    setHeader();

    popupMenu.addEventListener('mouseleave', function() {
        hideMenu();
    });

    hamburgerMenu.addEventListener('mouseleave', function() {
        hideMenu();
    });

    hamburgerCartMenu.addEventListener('mouseleave', function() {
        hideMenu();
    });

    roleTitle.addEventListener('click', openCloseDropdown);
    locTitle.addEventListener('click', openCloseDropdown);

    downloadPDFPrompt.querySelector('.prompt-bg').addEventListener('click', function() {
        downloadPDFPrompt.style.display = 'none';
        downloadPDFPrompt.style.opacity = 0;
    });

    downloadPdfBtn.addEventListener('click', function() {
        if (PDFvalid) {
            goPDFDownload();
            downloadPDFPrompt.style.display = 'none';
            downloadPDFPrompt.style.opacity = 0;
            // pdfNameInput.value = 'Type crew list name';
            // pdfNameInput.style.color = '#000000';
            // downloadPdfValidation.style.color = '#000000';
            // PDFvalid = false;
            downloadPdfBtn.style.opacity = 0.3;
            downloadPdfBtn.style.cursor = 'no-drop';
        }
    });

    downloadPdfBtn.addEventListener('mouseover', function() {
        if (PDFvalid) {
            downloadPdfBtnImg.style.backgroundPosition = '-20px';
            downloadPdfBtnTxt.style.color = '#b59343';
            downloadPdfBtn.style.border = 'solid 1px #b59343';
        }
    });

    downloadPdfBtn.addEventListener('mouseout', function() {
        if (PDFvalid) {
            downloadPdfBtnImg.style.backgroundPosition = '0px';
            downloadPdfBtnTxt.style.color = '#000000';
            downloadPdfBtn.style.border = 'solid 1px #000000';
        }
    });

    cancelBtn.addEventListener('click', function() {
        // pdfNameInput.value = 'Type crew list name';
        downloadPDFPrompt.style.display = 'none';
        downloadPDFPrompt.style.opacity = 0;
    });

    cancelBtn.addEventListener('mouseover', function() {
        cancelBtnImg.style.backgroundPosition = '-20px';
        cancelBtnTxt.style.color = '#b59343';
        cancelBtn.style.border = 'solid 1px #b59343';
    });

    cancelBtn.addEventListener('mouseout', function() {
        cancelBtnImg.style.backgroundPosition = '0px';
        cancelBtnTxt.style.color = '#000000';
        cancelBtn.style.border = 'solid 1px #000000';
    });

    deleteCartPrompt.querySelector('.prompt-bg').addEventListener('click', function() {
        deleteCartPrompt.style.display = 'none';
    });

    deleteCartBtn.addEventListener('click', function() {
        emptyCart();
        deleteCartPrompt.style.display = 'none';
    });

    deleteCartBtn.addEventListener('mouseover', function() {
        deleteCartBtnImg.style.backgroundPosition = '-20px';
        deleteCartBtnTxt.style.color = '#b59343';
        deleteCartBtn.style.border = 'solid 1px #b59343';
    });

    deleteCartBtn.addEventListener('mouseout', function() {
        deleteCartBtnImg.style.backgroundPosition = '0px';
        deleteCartBtnTxt.style.color = '#000000';
        deleteCartBtn.style.border = 'solid 1px #000000';
    });

    deleteCartCancel.addEventListener('click', function() {
        deleteCartPrompt.style.display = 'none';
    });

    deleteCartCancel.addEventListener('mouseover', function() {
        deleteCartCancelImg.style.backgroundPosition = '-20px';
        deleteCartCancelTxt.style.color = '#b59343';
        deleteCartCancel.style.border = 'solid 1px #b59343';
    });

    deleteCartCancel.addEventListener('mouseout', function() {
        deleteCartCancelImg.style.backgroundPosition = '0px';
        deleteCartCancelTxt.style.color = '#000000';
        deleteCartCancel.style.border = 'solid 1px #000000';
    });
}
/**
 * Assembles shopping cart string for export to PDF, Email, & clipboard
 * @param {String} separator element id
 */
function buildCartDataString(separator) {// creates a string of data from the cart. the separator is determined by the type of string needed
    cartDataString = '';
    for (let n in cartArray) {
        if (gData[n]!==null) {
            let str = '';
            if (parseInt(n)+1 === cartArray.length) separator = '';
            str += cartArray[n].position+' ';
            str += cartArray[n].name+' ';
            str += cartArray[n].email+' ';
            str += cartArray[n].phone+' ';
            if (cartArray[n].city.length>0) str += cartArray[n].city+' ';
            if (cartArray[n].state.length>0) str += cartArray[n].state+' ';
            str += cartArray[n].country;
            str += separator;
            cartDataString += str;
        }
    }
}
/**
 * What does this function do?
 */
function promptPDFDownload() {
    buildCartDataString(',');
    console.log('SEND AJAX VIA JQUERY test2');
    /*
    $.ajax({
        url: 'pdf/?pdfText='+cartDataString+'',
        type: 'GET',
    });
    */

    // pdfNameInput.value === 'Type crew list name';
    // pdfNameInput.style.color = '#9b9b9b';
    // downloadPdfValidation.style.color = '#000000';

    downloadPDFPrompt.style.display = 'block';
    downloadPDFPrompt.style.opacity = 1;
}
/**
 * What does this function do?
 */
function goPDFDownload() {
    // '?titleText='+pdfNameInput.value+'&pdfText='+cartDataString+''
    // window.location = 'pdf2/?title='+pdfNameInput.value+'';
    window.location = 'pdf2/';
}
/**
 * What does this function do?
 */
function promptExcelDownload() {
    console.log('Build Excel sheet');
}
/**
 * What does this function do?
 */
function displayResults() {// populate the results table
    /**
     * What does this function do?
     * @param {*} s what is this parameter?
     * @param {*} n what is this parameter?
     * @param {*} row what is this parameter?
     */
    function buildCell(s, n, row) {// build out the headers of each column
        let item = document.createElement('td');
        row.appendChild(item);
        item.innerHTML = s;
        item.style.cssText = 'cursor:pointer;';

        item.addEventListener('click', function(e) {
            let thisCell = e.target;
            let thisRow = thisCell.parentElement;
            if (thisCell.id.indexOf('rowMenu')!==-1) {
                popupMenu.style.display = 'block';
                popupMenu.style.top = (thisRow.offsetTop-((popupMenu.offsetHeight-thisRow.offsetHeight)/2))+'px';// position menu in center of row no matter how tall
                currentResult = n;
            } else {
                let cb = document.getElementById('checkbox'+n);
                if (cb.className === 'resultsCb checked') {
                    cb.className = 'resultsCb';

                    for (let i in cartArray) {
                        if (resultsArray[n]===cartArray[i]) {
                            resultsArray[n].selected = false;// update checkmark on displayed results
                            cartArray.splice(i, 1);
                            updateCart();
                            break;
                        }
                    }
                } else {
                    cb.className = 'resultsCb checked';
                    cartArray.push(resultsArray[n]);// if checked update cart
                    resultsArray[n].selected = true;// update checkmark on displayed results
                    updateCart();
                }
            }
        });
    }

    clearTable(resultsTable);

    let headerRow = document.createElement('tr');
    resultsTable.appendChild(headerRow);
    headerRow.className = 'headerRow';

    for (let e=0; e < headers.length; e++) {
        let hCell = document.createElement('td');
        headerRow.appendChild(hCell);
        hCell.innerHTML = headers[e];
    }

    let headHamburger = gid('headHamburger');
    headHamburger.addEventListener('click', function(e) {
        // let thisCell = e.target;
        hamburgerMenu.style.display = 'block';
    });

    totalHits.innerHTML = '(&nbsp;'+resultsArray.length+'&nbsp;)';// populate hit counter in results header
    totalHits.style.display = 'block';

    for (let d in resultsArray) {
        if (resultsArray[d]) {
            let row = document.createElement('tr');
            resultsTable.appendChild(row);
            row.className = 'resultRow';
            row.setAttribute('rowIndex', d);

            let isSelected = '';
            if (resultsArray[d].selected) {
                isSelected = ' checked';
            }

            buildCell(parseInt(d)+1, d, row);
            buildCell('<div id="checkbox'+d+'" class="resultsCb'+isSelected+'"></div>', d, row);
            buildCell(structureItem(resultsArray[d].city, resultsArray[d].state, resultsArray[d].country), d, row);
            buildCell(resultsArray[d].position, d, row);
            buildCell(resultsArray[d].name, d, row);
            buildCell(resultsArray[d].email, d, row);
            buildCell(resultsArray[d].phone, d, row);
            buildCell(resultsArray[d].notesspecializations, d, row);
            buildCell('<div id="rowMenu'+d+'" class="row-menu">...</div>', d, row);
        }
    }
}
/**
 * What does this function do?
 * @param {*} o what is this parameter?
 * @param {*} n what is this parameter?
 */
function findAndRemoveSelected(o, n) {
    for (let d in gData) {// cycle through the main data
        if (o===gData[d]) {// match the item being removed against the main data src
            cartArray.splice(n, 1);// remove item from cart
            gData[d].selected = false;// change item in main data source to unselected
            displayResults();// refresh results
            updateCart();// refresh cart
            break;
        }
    }
}
/**
 * What does this function do?
 */
function emptyCartPrompt() {
    deleteCartPrompt.style.display = 'block';
    deleteCartPrompt.style.opacity = 1;
}
/**
 * What does this function do?
 */
function emptyCart() {
    cartArray=[];
    for (let d in gData) {// cycle through the main data
        if (gData[d]!==null) {
            gData[d].selected = false;// change item in main data source to unselected
        }
    }
    displayResults();// refresh results
    updateCart();// refresh cart
}
/**
 * What does this function do?
 */
function updateCart() {
    clearTable(cartTable);

    if (cartArray.length!==0) {
        resultsField.style.width = '74%';
        cartField.style.width = '25%';
        sortArray(cartArray, 'position');

        let cartHeaderRow = document.createElement('tr');
        cartTable.appendChild(cartHeaderRow);
        cartHeaderRow.className = 'cartHeaderRow';

        let hCell = document.createElement('td');
        cartHeaderRow.appendChild(hCell);
        hCell.innerHTML = 'CREW LIST (&nbsp;'+cartArray.length+'&nbsp;) <div id="cartBurger" class="cart-burger"></div><div id="cartTrash" class="cart-trash"></div> ';
        let cartTrash = gid('cartTrash');
        cartTrash.addEventListener('click', emptyCartPrompt);
        /*
        let cartPDF = gid('cartPDF');
        cartPDF.addEventListener('click', promptPDFDownload);
        */
        let cartBurger = gid('cartBurger');
        console.log('cartBurger '+cartBurger);
        cartBurger.addEventListener('click', function(e) {
            hamburgerCartMenu.style.display = 'block';
        });

        for (let d in cartArray) {
            if (cartArray[d]!==null) {
                let row = document.createElement('tr');
                cartTable.appendChild(row);
                row.className = 'cartRow';
                row.setAttribute('cellIndex', d);

                let item = document.createElement('td');
                row.appendChild(item);
                item.className = 'cartCell';
                let innerStr ='<div class="cart-item-wrapper"><div class="cart-position">'+cartArray[d].position+'</div><div class="cart-name">'+cartArray[d].name+'</div>';
                if (cartArray[d].phone.length!==0) innerStr += '<div class="cart-phone">'+cartArray[d].phone+'</div>';
                if (cartArray[d].email.length!==0) innerStr += '<div class="cart-email">'+cartArray[d].email+'</div>';
                innerStr += '</div>';
                item.innerHTML = innerStr;

                let removeItem = document.createElement('td');
                item.appendChild(removeItem);
                removeItem.className = 'removeItem';
                removeItem.id = 'removeItem'+d;

                removeItem.addEventListener('click', function(e) {
                    // let thisClose = e.target;
                    findAndRemoveSelected(cartArray[d], d);
                });
            }
        }
    } else {
        resultsField.style.width = '100%';
        cartField.style.width = '0%';
    }
}
/**
 * What does this function do?
 * @param {*} o what is this parameter?
 * @param {*} arr what is this parameter?
 * @param {*} s what is this parameter?
 */
function setUniqueArray(o, arr, s) {
    let match = false;
    for (let h in arr) {
        if (o === arr[h]) {
            match = true;
            break;
        }
    }
    if (!match) arr.push(o);
    if (s) arr.sort();// one off hack to not sort an already sorted array
}
/**
 * What does this function do?
 */
function setHeader() {
    headers.push('#');
    headers.push('&nbsp;');
    for (let n in gData[0]) {// populate array for result headers
        if (gData[n]!==null) {
            if (n==='city') headers.push('LOCATION');
            if (n==='position') headers.push('POSITION');
            if (n==='name') headers.push('NAME');
            if (n==='email') headers.push('EMAIL');
            if (n==='phone') headers.push('PHONE');
            if (n==='notesspecializations') headers.push('NOTES / SPECIALIZATIONS');
        }
    }
    headers.push('<div id="headHamburger" class="hamburgerHead"></div>');
}
/**
 * What does this function do?
 */
function setDropDownArrays() {// initially populate drop downs
    roleArray = [];
    locsArray = [];
    let domesticArray = [];
    let internationalArray = [];

    for (let i in gData) {
        if (gData[i]!==null) {
            setUniqueArray(gData[i].position, roleArray, true);// build out menu Items in drop down. Filter out any duplicate seletions
            if (gData[i].country==='United States') {
                setUniqueArray(gData[i].location, domesticArray, true);
            } else {
                setUniqueArray(gData[i].location, internationalArray, true);// Do the same for the location drop down
            }
        }
    }
    locsArray = domesticArray.concat(internationalArray);
}
/**
 * What does this function do?
 * @param {*} t what is this parameter?
 */
function clearTable(t) {
    while (t.rows.length > 0) {
        t.deleteRow(0);
    }
}
/**
 * What does this function do?
 * @param {*} e what is this parameter?
 */
function cellClick(e) {
    let cell = e.target;
    let cellId = cell.id;
    let cellArr = cellId.split('_');
    let rootName = cellArr[0];
    // let cellNum = cellArr[1];

    let wrapper = document.getElementById(rootName+'Wrapper');
    toggleDropdown(wrapper);

    if (rootName==='role') {
        if (!initSelect) filterFirstSelect = true;
        d1value = cell.innerHTML;
    } else {
        if (!initSelect) filterFirstSelect = false;
        d2value = cell.innerHTML;
    }
    initSelect = true;

    querySelections(rootName);
}

/**
 * What does this function do?
 * @param {*} parent what is this parameter?
 * @param {*} n what is this parameter?
 * @param {*} data what is this parameter?
 */
function setOption(parent, n, data) {
    let row = document.createElement('tr');
    parent.appendChild(row);
    row.className = 'dropdownRow';

    let cell = document.createElement('td');
    row.appendChild(cell);
    cell.innerHTML = data;
    cell.id = parent.id+'_'+n;
    cell.addEventListener('click', cellClick);
}

/**
 * What does this function do?
 * @param {*} ele what is this parameter?
 * @param {*} data what is this parameter?
 */
function setTitle(ele, data) {
    let title = document.getElementById(ele);
    title.innerHTML = data;
}

/**
 * What does this function do?
 * @param {*} ele what is this parameter?
 * @param {*} arr what is this parameter?
 * @param {*} val what is this parameter?
 */
function setDropDown(ele, arr, val) {
    clearTable(ele);
    setTitle(ele.id+'Title', val);
    let dropInc = 0;
    for (let j in arr) {
        if (arr[j]!==null) {
            let item = arr[j];
            if (item !== null) {
                setOption(ele, dropInc, item);
                dropInc++;
            } else break;
        }
    }
    let menu = document.getElementById(ele.id+'Menu');
    let maxItems = 6;// how many items to display in menu before set cutoff

    if (dropInc < maxItems) {
        menu.style.height = (40*dropInc)+'px';// set the menu height to the height of items if less than cutoff
    } else {
        menu.style.height = (40*maxItems)+'px';// set cut off for menu size
    }
}

/**
 * What does this function do?
 */
function setDropDowns() {
    setDropDown(role, roleArray, title1);
    setDropDown(loc, locsArray, title2);
}

/**
 * What does this function do?
 */
function resetSearch() {
    initSelect = false;// clear boolean that initally sets which dropdown is selected first
    d1value = '';// clear selected search values
    d2value = '';
    setDropDownArrays();// set dropdown data to default content
    setDropDowns();// reset dropdowns to default content
    clearTable(resultsTable);// clear results from table
    search.value = 'Search';// reset search box text
    document.getElementById('roleWrapper').className = 'dropdownWrapper closed';// close dropdowns
    document.getElementById('locWrapper').className = 'dropdownWrapper closed';
    resultsArray = [];
    totalHits.style.display = 'none';
    paramWrapper1.style.display = 'none';
    paramWrapper2.style.display = 'none';
}

/**
 * What does this function do?
 * @param {*} arr what is this parameter?
 * @param {*} key what is this parameter?
 */
function sortArray(arr, key) {
    arr.sort(function(a, b) {
        if (a[key] < b[key]) return -1;
        if (a[key] > b[key]) return 1;
        return 0;
    });
}

/**
 * What does this function do?
 * @param {*} ele what is this parameter?
 */
function querySelections(ele) {
    // configure the variables depending on which dropdown was selected first
    let selected1 = filterFirstSelect?'position':'location';
    let selected2 = filterFirstSelect?'location':'position';
    let Title = filterFirstSelect?title2:title1;
    let elementToUpdate = filterFirstSelect?loc:role;
    let initialElement = filterFirstSelect?'role':'loc';
    // let oppositeElement = filterFirstSelect?'loc':'role';
    let element = ele;
    let q1 = filterFirstSelect?d1value:d2value;
    let q2 = filterFirstSelect?d2value:d1value;

    if (element===initialElement) {
        q2='';
    }

    // let test = filterFirstSelect?'dropdown 1':'dropdown 2';

    resultsArray = [];
    let resultsDomArray = [];
    let resultsIntArray = [];
    tmp = [];

    if ((q1.length > 0) && (q2.length > 0)) {
        searchParameter2.innerHTML = q2.toUpperCase();
        paramWrapper2.style.display = 'block';
        for (let b in gData) {
            if ((q1 === gData[b][selected1]) && (q2 === gData[b][selected2])) {
                // separate domestic and international results into separate arrays
                if (gData[b].country==='United States') resultsDomArray.push(gData[b]);
                else resultsIntArray.push(gData[b]);
            }
        }
    } else {
        searchParameter1.innerHTML = q1.toUpperCase();
        paramWrapper1.style.display = 'block';
        paramWrapper2.style.display = 'none';// hide 2nd parameter
        for (let c in gData) {
            if (q1 === gData[c][selected1]) {
                // separate domestic and international results into separate arrays
                if (gData[c].country==='United States') resultsDomArray.push(gData[c]);
                else resultsIntArray.push(gData[c]);
            }
        }
    }
    // sort and join separate arrays into one results array

    if (selected1==='position') {
        sortArray(resultsDomArray, 'city');
        sortArray(resultsIntArray, 'country');
    } else {
        sortArray(resultsDomArray, 'position');
        sortArray(resultsIntArray, 'position');
    }

    resultsArray = resultsDomArray.concat(resultsIntArray);

    for (let e in resultsArray) {
        if (resultsArray[e]!==null) {
            setUniqueArray(resultsArray[e][selected2], tmp, false);// set available results in opposite dropdown
        }
    }

    displayResults();

    if ((q1.length > 0) && (q2.length > 0)) {
        // console.log('Both Dropdowns selected');
    } else {
        setDropDown(elementToUpdate, tmp, Title);
    }
}

/**
 * What does this function do?
 * @param {*} wrapper what is this parameter?
 */
function toggleDropdown(wrapper) {
    if (wrapper.className === 'dropdownWrapper closed') {// toggle selected dropdown open and closed
        wrapper.className = 'dropdownWrapper open';
    } else {
        wrapper.className = 'dropdownWrapper closed';
    }
}

/**
 * What does this function do?
 * @param {*} e what is this parameter?
 */
function openCloseDropdown(e) {
    let rootName = e.target.id.split('Title').join('');
    let wrapper = document.getElementById(rootName+'Wrapper');
    let other = 'role';

    if (rootName==='role') other = 'loc';// create var for opposite dropdown control

    let wrapper2 = document.getElementById(other+'Wrapper');
    if (wrapper2.className === 'dropdownWrapper open') wrapper2.className = 'dropdownWrapper closed';// shut opposite dropdown if it's open

    toggleDropdown(wrapper);
}

/**
 * What does this function do?
 * @param {*} e what is this parameter?
 */
function setCursor(e) {
    if (search.value === 'Search') search.value = '';
}

/**
 * What does this function do?
 * @param {*} e what is this parameter?
 */
function searchSuggestion(e) {
    let hit = false;
    /**
     * What does this function do?
     * @param {*} c what is this parameter?
     * @param {*} a what is this parameter?
     */
    function searchSpecificColumn(c, a) {
        // console.log(s1.length+'---> '+gData[a][c].toUpperCase().indexOf(s1.toUpperCase())+' '+gData[a][c]);
        if (gData[a][c].toUpperCase().indexOf(s1.toUpperCase()) != -1) {// compare search text to what in the column
            resultsArray.push(gData[a]);
            hit = true;
        }
    }

    if ((e.keyCode || e.which)=== 13) e.preventDefault();
    let s1 = search.value;
    resultsArray = [];
    for (let a in gData) {// loop through each row
        if (gData[a]!==null) {
            hit = false;
            // SEARCH ONLY SPECIFIC CELLS
            if (s1.length >= 1) {
                searchSpecificColumn('name', a);
                if (!hit) searchSpecificColumn('phone', a);
                if (!hit) searchSpecificColumn('email', a);
                if (!hit) searchSpecificColumn('notesspecializations', a);
            }
        /*
        if (!hit) searchSpecificColumn('role',a);
        if (!hit) searchSpecificColumn('location',a);
        if (!hit) searchSpecificColumn('country',a);
        if (!hit) searchSpecificColumn('state',a);
        */
        }
    }
    displayResults();
}

/**
 * What does this function do?
 */
function hideMenu() {
    popupMenu.style.display = 'none';
    hamburgerMenu.style.display = 'none';
    hamburgerCartMenu.style.display = 'none';
}

/**
 * What does this function do?
 */
function menuAdd() {
    if (!resultsArray[currentResult].selected) {
        let cb = document.getElementById('checkbox'+currentResult);
        cb.className = 'resultsCb checked';
        cartArray.push(resultsArray[currentResult]);// if checked update cart
        resultsArray[currentResult].selected = true;// update checkmark on displayed results
        updateCart();
    }
}

/**
 * What does this function do?
 * @param {*} d what is this parameter?
 * @return {*} what does this return?
 */
function getDataFromRow(d) {
    d = resultsArray[currentResult].name+' ';
    d += resultsArray[currentResult].position+' ';
    d += resultsArray[currentResult].email+' ';
    d += resultsArray[currentResult].phone+' ';
    d += resultsArray[currentResult].city+' ';
    d += resultsArray[currentResult].state+' ';
    d += resultsArray[currentResult].country+' ';
    d += resultsArray[currentResult].notesspecializations;
    return d;
}

/**
 * What does this function do?
 */
function menuCopy() {
    let info = '';
    clipBoard.innerHTML = getDataFromRow(info);
    clipBoard.select();
    try {
        let successful = document.execCommand('copy');
        let msg = successful ? 'successful' : 'unsuccessful';
        console.log('Copying text command was ' + msg);
    } catch (err) {
        console.log('Oops, unable to copy');
    }
}

/**
 * What does this function do?
 */
function menuShare() {
    console.log('SHARE');
    let info = '';
    window.location.href = 'mailto:?subject=From the Freelance Rolodex...&body='+getDataFromRow(info);
}

/**
 * What does this function do?
 */
function selectAll() {
    for (let e in resultsArray) {
        if (!resultsArray[e].selected) {
            let cb = document.getElementById('checkbox'+e);
            cb.className = 'resultsCb checked';
            cartArray.push(resultsArray[e]);// if checked update cart
            resultsArray[e].selected = true;// update checkmark on displayed results
        }
    }
    updateCart();
}

/**
 * What does this function do?
 */
function deselectAll() {
    for (let e in resultsArray) {
        if (resultsArray[e]!==null) {
            for (let i in cartArray) {
                if (resultsArray[e]===cartArray[i]) {
                    let cb = document.getElementById('checkbox'+e);
                    cb.className = 'resultsCb';
                    resultsArray[e].selected = false;// update checkmark on displayed results
                    cartArray.splice(i, 1);
                }
            }
        }
    }
    updateCart();
}

/**
 * What does this function do?
 */
function cartCopy() {
    buildCartDataString('\n\r');
    clipBoard.innerHTML = cartDataString;
    clipBoard.select();
    try {
        let successful = document.execCommand('copy');
        let msg = successful ? 'successful' : 'unsuccessful';
        console.log('Copying text command was ' + msg);
    } catch (err) {
        console.log('Oops, unable to copy');
    }
}
/**
 * What does this function do?
 */
function cartShare() {
    buildCartDataString('%0D%0A%0D%0A');
    console.log(cartDataString);
    window.location.href = 'mailto:?subject=From the Freelance Rolodex...&body='+cartDataString;
}
/*
var setCursorPDF = function(e) {
    if (pdfNameInput.value === 'Type crew list name') pdfNameInput.value = '';
};

var nameValidationPDF = function(e) {
    if (pdfNameInput.value.length > 0) {
        pdfNameInput.style.color = '#000000';
        downloadPdfValidation.style.color = '#000000';
    };

    if ((pdfNameInput.value.match('^[a-zA-Z0-9_\w -]*$') && (pdfNameInput.value.length >= 1))) {
        PDFvalid = true;
        downloadPdfBtn.style.opacity = 1;
        downloadPdfBtn.style.cursor = 'pointer';
    }
    else{
        PDFvalid = false;
        if (pdfNameInput.value.length > 0) {
            pdfNameInput.style.color = '#ff0000';
            downloadPdfValidation.style.color = '#ff0000';
            }
        downloadPdfBtn.style.opacity = 0.3;
        downloadPdfBtn.style.cursor = 'no-drop';

    }
};
*/

const rolowrapper=gid('rolowrapper');
const clipBoard = gid('clipBoard');
const role = gid('role');
const loc = gid('loc');
const roleMenu = gid('roleMenu');
const locMenu = gid('locMenu');
const roleTitle = gid('roleTitle');
const locTitle = gid('locTitle');
const searchParameter1 = gid('search_parameter1');
const searchParameter2 = gid('search_parameter2');
const paramWrapper1 = gid('param_wrapper1');
const paramWrapper2 = gid('param_wrapper2');
const resetBtn = gid('reset_results_btn');
const search = gid('searchFilter');
const totalHits = gid('total_hits');
const resultsTable = gid('resultsTable');
const downloadPDFPrompt = gid('downloadPDFPrompt');
const deleteCartPrompt = gid('deleteCartPrompt');
const cartTable = gid('cartTable');
const popupMenu = gid('popupMenu');
const hamburgerMenu = gid('hamburgerMenu');
const hamburgerCartMenu = gid('hamburgerCartMenu');
const resultsField = gid('resultsField');
const cartField = gid('cartField');
// const pdfNameInput = gid('pdfNameInput');
const downloadPdfBtn = downloadPDFPrompt.querySelector('.download-pdf-btn');
const downloadPdfBtnTxt = downloadPDFPrompt.querySelector('.download-pdf-btn-txt');
const downloadPdfBtnImg = downloadPDFPrompt.querySelector('.download-pdf-btn-img');
const cancelBtn = downloadPDFPrompt.querySelector('.download-pdf-cancel');
const cancelBtnTxt = downloadPDFPrompt.querySelector('.download-pdf-cancel-txt');
const cancelBtnImg = downloadPDFPrompt.querySelector('.download-pdf-cancel-img');
// const downloadPdfValidation = downloadPDFPrompt.querySelector('.download-pdf-validation');
const deleteCartBtn = deleteCartPrompt.querySelector('.delete-cart-btn');
const deleteCartBtnImg = deleteCartPrompt.querySelector('.delete-cart-btn-img');
const deleteCartBtnTxt = deleteCartPrompt.querySelector('.delete-cart-btn-txt');
const deleteCartCancel = deleteCartPrompt.querySelector('.delete-cart-cancel');
const deleteCartCancelTxt = deleteCartPrompt.querySelector('.delete-cart-cancel-txt');
const deleteCartCancelImg = deleteCartPrompt.querySelector('.delete-cart-cancel-img');
let resultsArray = [];// array to hold the results of queries
let cartArray = [];
let headers = [];// array for result headers
let roleArray = [];// array for roles drop down
let locsArray = [];// array for location drop down
let tmp = [];// temporary array to store filtered dropdown results
let d1value = '';
let d2value = '';
let initSelect = false;
let filterFirstSelect = true;// variable to determin how the drop downs will behave. The first selected will maintain all of its items. The second will filter. Selecting a new item on the first select will refilter the second list
let title1 = 'Position';
let title2 = 'Location';
let PDFvalid = true;
let cartDataString = '';
let currentResult = '';
let gData;
